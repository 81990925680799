import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/app.service';

@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  projects$: Observable<any>;
  projects: any[] = [];

  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
    this.projects$ = this._apiService.get('https://mewbf4hivi.execute-api.us-east-1.amazonaws.com/dev/projects');
    this.projects$.subscribe(res => {
      if (res && res.body && res.body.Items) {
        this.projects = res.body.Items;
      }
    });
  }

}
