<section id="projects">
  <div class="container">
    <h1 class="text-center mt-4">Projects</h1>
    <div class="row">
      <div class="col-lg-4 col-md-6 my-4" *ngFor="let project of projects">
        <div class="card">
          <div class="card-body">
            <h3>{{project.name}}</h3>
            <p>{{project.description}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>